@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
  position: relative;
}

.damn{
  overflow-y: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

.a .img{
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  margin-right: 10px;
  border-width: 5px;
  border-color: black;
  background: #000;
}

/* a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
} */

a{
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  li{
    @apply p-4
  }

  button{
    @apply text-black border bg-primary
     hover:bg-black hover:border-primary hover:text-primary
  }
  
}